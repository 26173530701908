import { AsyncFn, EventHook, Log } from '../../common'
import { CompanyGuid, ForCompany } from '../Company/Company'
import { BreezySystemUserGuid, UserGuid } from '../Users/User'

export const FEATURE_FLAGS = [
  'testFlag',
  'billingAddresses',
  'newTechAppHeader',
  'zapier',
  'platformVersionMismatchNotification',
  'useAiRecommendations',
  'showAiRecommendations',
  'selfServeLeadSources',
  'selfServeBillingProfile',
  'cronsEnabled',
  'frontendAlerts',
  'installPwaPrompt',
  'linkedJobs',
  'disableQboClientTokenCache',
  'jobsScoreCards',
  'backendContextFix',
  'integratedCalling',
  'customer-reviews',
  'dripMarketing',
  'changeJobType',
  'google-reviews-enabled',
  'yelp-reviews-enabled',
  'facebook-reviews-enabled',
  'angi-reviews-enabled',
  'bbb-reviews-enabled',
  'disableJobsListView',
  'qbo-refresh-with-lock',
  'label-scanner',
  'should-hard-refresh',
  'should-hard-refresh-killswitch',
  'maintenance-plans-listing-page',
  'dynamic-pricing',
  'job-leads',
  'current-visit-card',
  'tech-app-home-map',
  'tech-app-home-docket',
  'hide-caller-id',
  'assistant-settings',
  'visit-confirmation',
  'visitDetailsPhotoAlbumWidget',
  'navigation-platform-refresh-disabled',
  'instant-booking',
  'visitDetailsFilesWidget',
  'visit-details-past-visit-card',
  'commercial-account-improvements',
  'multi-year-maintenance-plans',
  'businessUnits',
  'lapsed-payment-notifications-for-maintenance-plans',
] as const

export type FeatureFlag = (typeof FEATURE_FLAGS)[number]

export type FeatureFlags = Record<FeatureFlag, boolean>

export type FeatureFlagReader = <T extends FeatureFlag>(
  key: T,
  companyGuid: CompanyGuid | undefined,
  userGuid: UserGuid | typeof BreezySystemUserGuid,
) => Promise<boolean>

export type FeatureFlagClient = {
  read: FeatureFlagReader
  close: () => void
}

export type FeatureFlaggedForCompany<T, TOutput = void, TInput = ForCompany<T>, Fn = AsyncFn<TInput, TOutput>> = {
  featureFlag: FeatureFlag
  featureFlagReader: FeatureFlagReader
  enabledFn: Fn
  disabledFn: Fn
  logFlagValues?: boolean
}

export const createFeatureFlaggedForCompany = <TInput, TOutput = void>({
  featureFlag,
  featureFlagReader,
  enabledFn,
  disabledFn,
  logFlagValues = false,
}: FeatureFlaggedForCompany<TInput, TOutput>): AsyncFn<ForCompany<TInput>, TOutput> => {
  return async input => {
    try {
      const featureFlagEnabled = await featureFlagReader(featureFlag, input.companyGuid, BreezySystemUserGuid)
      if (logFlagValues) {
        Log.info('Feature flag value', { featureFlag, featureFlagEnabled, companyGuid: input.companyGuid })
      }

      if (featureFlagEnabled) {
        return await enabledFn(input)
      }
      return await disabledFn(input)
    } catch (err) {
      Log.warn('Could not read feature flag', { featureFlag, input })
      return await disabledFn(input)
    }
  }
}

export type FeatureFlagEventWriterConfig = {
  featureFlag: FeatureFlag
  featureFlagReader: FeatureFlagReader
  enabledWriter: EventHook
  disabledWriter?: EventHook
}

export const createFeatureFlaggedEventWriter = ({
  featureFlag,
  featureFlagReader,
  enabledWriter,
  disabledWriter = async _ => {},
}: FeatureFlagEventWriterConfig): EventHook => {
  return async input => {
    try {
      const featureFlagEnabled = await featureFlagReader(featureFlag, input.companyGuid, input.actingUserGuid)

      if (featureFlagEnabled) {
        return await enabledWriter(input)
      }
      return await disabledWriter(input)
    } catch (err) {
      Log.warn('Could not read feature flag', { featureFlag, input })
      return await disabledWriter(input)
    }
  }
}

export const UNAUTH_USER_KEY = 'unauthenticated'
